import { useEffect, useState } from "react";
import DHeader from "../components/Header";
import { FetchCarousels, NewCarousel, UpdateCarousel } from "../../../Redux/apiCalls";
import SlideDeleteModal from "../../Modals/SlideDeleteModal";
import Add from '../../../asset/add.svg';
import { ToastContainer, toast } from "react-toastify";
import Loader from "../../../Utils/Loader/Loader";


function SlideUpdate() {
    const [carousels, setCarousels] = useState([]);
    const [links, setLinks] = useState(carousels.map(() => ''));
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [currentCarousel, setCurrentCarousel] = useState({});
    const [isCarouselModalOpen, setIsCarouselModalOpen] = useState(false);

    const GetCarousels = async () => {
        const _Tmp = await FetchCarousels();
        setCarousels(_Tmp.carousel);
    }

    const UpdateCarouselLink = async (carouselId, index) => {
        if (links[index] === '') {
            console.log("Not allowed")
        }
        else {
            console.log(links[index])
            let payload = {
                filePath: links[index]
            }
            const _Tmp = await UpdateCarousel(carouselId, payload);
            console.log(_Tmp);
        }
    }

    const handleLinkChange = (index, value) => {
        const newLinks = [...links];
        newLinks[index] = value;
        setLinks(newLinks);
    };

    useEffect(() => {
        GetCarousels();
    }, []);

    const openModal = (data) => {
        setIsModalOpen(true);
        setCurrentCarousel(data);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const openCarouselModal = () => {
        setIsCarouselModalOpen(true);
    };

    const closeCarouselModal = () => {
        setIsCarouselModalOpen(false);
    };

    return (
        <div className="bg-black min-h-screen">
            <DHeader />

            <div className="bg-[#151515] p-16 m-16 rounded-lg">
                <div className="flex justify-between items-center">
                    <div>
                        <h3 className="text-xl text-[#D9D9D9] mb-5">Total Slides</h3>
                        <p className="text-2xl text-[#D9D9D9] font-extrabold font-[Oxta] mb-5">{carousels?.length}</p>
                    </div>

                    <div className="flex items-center space-x-8">
                        <div onClick={openCarouselModal} className="flex items-center space-x-2 cursor-pointer">
                            <h3 className="text-xl text-[#D9D9D9] ">Add Slide</h3>
                            <img src={Add} alt="Export" className="w-6 h-6" />
                        </div>
                        <div className="mb-2 md:mb-4 mt-3">
                            {/* <input
                                type="text"
                                className="w-full h-12 px-4 py-2 border-radius focus:outline-none text-[#D9D9D9] placeholder-[#D9D9D9]"
                                placeholder='Search User Id'
                                style={{ background: '#545454', }}
                                value={searchValue}
                                onChange={handleSearchChange}
                            /> */}
                        </div>
                    </div>


                </div>

                <div className="max-w-2xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:max-w-7xl lg:px-8">
                    <div className="flex flex-wrap justify-center gap-4 mb-5">
                        {
                            carousels.map((carousel, i) => (
                                <div className="w-80 bg-[#1E1E1E] border-radius shadow-md rounded-lg p-4" style={{ boxShadow: '5px 6px 0 0 #000000' }} key={i}>
                                    <div className="flex items-center space-x-4">
                                        <img src={carousel.filePath}
                                            alt="slide"
                                            className="w-24 h-24 md:w-32 md:h-24 object-cover"
                                        />
                                        <p className="text-2xl text-[#D9D9D9] font-bold mb-5">{carousel.filename}</p>
                                    </div>

                                    <div className="my-5">
                                        <p className="text-[0.9rem] md:text-[1rem] text-[#fff] font-[200] mb-3">Update Image</p>
                                        <div className="flex justify-between space-x-4">
                                            <button className="bg-[#00FF87] text-[#000000] px-4 py-4 border-radius shadow-md flex items-center justify-center w-[150px]">
                                                <span className="mr-2 text-[1rem]">Upload</span>
                                            </button>

                                            <button className="bg-[#F44336] text-[#000000] px-4 py-4 border-radius shadow-md flex items-center justify-center font-extrabold w-[150px]">
                                                <span className="mr-2 text-[1rem]">Delete</span>
                                            </button>
                                        </div>
                                    </div>

                                    <div className="my-5">
                                        <p className="text-[0.9rem] md:text-[1rem] text-[#fff] font-[200] mb-0">Update Link</p>
                                        <span className="text-[0.9rem] md:text-[1rem] text-[#838383] font-[200]">www.preview-link.com</span>

                                        <div className="mb-2 md:mb-4 mt-3">

                                            <input
                                                type="text"
                                                className="w-full h-12 px-4 py-2 border-radius focus:outline-none text-[#000000] placeholder-[#000000]"
                                                placeholder='Link'
                                                style={{ background: '#545454', }}
                                                value={links[i]}
                                                onChange={(e) => handleLinkChange(i, e.target.value)}
                                            />

                                        </div>
                                        <div className="flex justify-between space-x-4 mt-3">
                                            <button onClick={() => UpdateCarouselLink(carousel._id, i)} disabled={links[i] === '' || links[i] === undefined} className="bg-[#FF9800] text-[#000000] px-4 py-4 border-radius shadow-md flex items-center justify-center w-[150px]">
                                                <span className="mr-2 text-[1rem]">Update</span>
                                            </button>

                                            <button onClick={() => openModal(carousel)} className="bg-[#F44336] text-[#000000] px-4 py-4 border-radius shadow-md flex items-center justify-center font-extrabold w-[150px]">
                                                <span className="mr-2 text-[1rem]">Delete</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }


                    </div>
                </div>

            </div>



            <SlideDeleteModal closeModal={closeModal} isModalOpen={isModalOpen} carousel={currentCarousel} />
            <AddCarouselModal closeModal={closeCarouselModal} isModalOpen={isCarouselModalOpen} />
        </div>
    )
}

export default SlideUpdate



export const AddCarouselModal = ({ closeModal, isModalOpen }) => {
    const [fileName, setFileName] = useState('');
    const [filePath, setFilePath] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const [selectedFile, setSelectedFile] = useState(null);

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setSelectedFile(file);
        console.log(file)
    };

    const handleClick = async () => {
        setIsLoading(true);
        try {

            if (fileName === '') {
                toast.warn("filename name is empty")
            }
            else {
                let payload = {
                    filename: fileName,
                    filePath: filePath
                }

                const formData = new FormData();
                formData.append('filename', fileName);
                formData.append('filePath', filePath);

            

                const info = await NewCarousel(payload);
               
                if (info.message === "Successful") {
                    toast.success("carousel added successfully");
                    setIsLoading(false);
                    closeModal();
                    window.location.reload();
                }
                else {
                    toast.warn("An error has occurred")
                    setIsLoading(false);
                }
            }



        } catch (error) {
            setIsLoading(false);
            toast.warn("An error has occurred")
        }
    }

    const CloseModal = async () => {
        closeModal();
       
    }
    return (
        <div>

            {isModalOpen && (
                <div className="fixed inset-0 flex items-center justify-center z-50">
                    <div onClick={CloseModal} className="modal-overlay absolute w-full h-full bg-[#000000] opacity-95"></div>

                    <ToastContainer autoClose={5000} />
                    <div className="modal-container  w-11/12 md:max-w-md mx-auto border-radius shadow-lg overflow-y-auto" style={{ zIndex: 9999, background: 'rgb(16 16 16 / 79%)' }}>
                        {/* Add your modal content here */}
                        <div className="modal-content py-4 text-left px-6">

                            <div className="my-12">
                                <input
                                    type="text"
                                    className="w-full h-12 px-4 py-2 border-radius focus:outline-none text-[#8D8D8D] placeholder-[#545454]"
                                    placeholder='Enter carousel title'
                                    style={{ background: 'rgb(20 20 20 / 79%)', fontSize: '12px' }}
                                    value={fileName}
                                    onChange={(e) => setFileName(e.target.value)}
                                />
                            </div>

                            <div className="my-12">
                                <input
                                    type="url"
                                    className="w-full h-12 px-4 py-2 border-radius focus:outline-none text-[#8D8D8D] placeholder-[#545454]"
                                    placeholder='Enter image link'
                                    style={{ background: 'rgb(20 20 20 / 79%)', fontSize: '12px' }}
                                    value={filePath}
                                    onChange={(e) => setFilePath(e.target.value)}
                                />

                            </div>

                            <div className="my-12">
                            <input
                                    type="file"
                                    className="w-full h-12 px-4 py-2 border-radius focus:outline-none text-[#8D8D8D] placeholder-[#545454]"
                                    placeholder='Enter carousel title'
                                    style={{ background: 'rgb(20 20 20 / 79%)', fontSize: '12px' }}
                                    onChange={handleFileChange}
                                />
                               
                            </div>

                            <div className="mb-4 ">
                                <button
                                    type="submit"
                                    className="bg-[#00FF87] flex justify-center items-center text-[#000000] font-[Oxta] font-[300] text-[1rem] py-4 px-4 w-full border-radius"
                                    onClick={handleClick}
                                >
                                    Add Carousel
                                </button>
                            </div>

                            {isLoading && <Loader />}


                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}
