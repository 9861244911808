import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SelectInput from "../Dashboard/components/SelectField";
import { useEffect, useState } from "react";
import { GetRoles, NewAdmin } from "../../Redux/apiCalls";

const AddAdminModal = ({ closeModal, isModalOpen }) => {
  const [selectedRole, setSelectedRole] = useState("");
  const [roles, setRoles] = useState([]);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [cPassword, setCPassword] = useState('');


  const handleSelectChange = (event) => {
    setSelectedRole(event.target.value);
  };

  const fetchRoles = async () => {
    try {
      const _Tmp = await GetRoles();
      const formattedRoles = _Tmp?.roles?.map((role) => ({
        value: role.id,
        label: role.name,
      }));
      setRoles([{ value: "", label: "Select role" }, ...formattedRoles]);
    } catch (error) {
      console.error("Error fetching roles: ", error);
    }
  };

  useEffect(() => {
    fetchRoles();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
        if(selectedRole === "" || username === '' || password === '' || cPassword === '') {
            toast.warn("All fields are required.");
        }
        else if(password !== cPassword) {
            toast.warn("Passwords do not match");
        }
        else {
            let payload = { username: username, password: password, roleId: selectedRole }

            let info = await NewAdmin(payload);
            console.log(info)
        }
    } catch (error) {
        console.error("Error adding admin: ", error)
    }
  }

  return (
    <div>
      {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          <div
            onClick={closeModal}
            className="modal-overlay absolute w-full h-full bg-[#000000] opacity-95"
          ></div>

          <ToastContainer autoClose={5000} />
          <div
            className="modal-container  w-11/12 md:max-w-md mx-auto border-radius shadow-lg overflow-y-auto"
            style={{ zIndex: 9999, background: "rgb(16 16 16 / 79%)" }}
          >
            {/* Add your modal content here */}
            <div className="modal-content py-4 text-left px-6">
              <div className="my-12">
                <input
                  type="text"
                  className="w-full h-12 px-4 py-2 border-radius focus:outline-none text-[#8D8D8D] placeholder-[#545454]"
                  placeholder="Enter Username"
                  style={{
                    background: "rgb(20 20 20 / 79%)",
                    fontSize: "12px",
                  }}
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                />
              </div>

              <div className="my-12">
                <input
                  type="password"
                  className="w-full h-12 px-4 py-2 border-radius focus:outline-none text-[#8D8D8D] placeholder-[#545454]"
                  placeholder="Enter Password"
                  style={{
                    background: "rgb(20 20 20 / 79%)",
                    fontSize: "12px",
                  }}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>

              <div className="my-12">
                <input
                  type="password"
                  className="w-full h-12 px-4 py-2 border-radius focus:outline-none text-[#8D8D8D] placeholder-[#545454]"
                  placeholder="Verify Password"
                  style={{
                    background: "rgb(20 20 20 / 79%)",
                    fontSize: "12px",
                  }}
                  value={cPassword}
                  onChange={(e) => setCPassword(e.target.value)}
                />
              </div>

              <div className="my-12 mb-24">
                {/* <Dropdown /> */}
                <SelectInput
                  options={roles}
                  value={selectedRole}
                  onChange={handleSelectChange}
                />
              </div>
              <div className="mb-4 ">
                <button
                onClick={handleSubmit}
                  type="submit"
                  className="bg-[#00FF87] flex justify-center items-center text-[#000000] font-[Oxta] font-[300] text-[1rem] py-4 px-4 w-full border-radius"
                >
                  Add Admin
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AddAdminModal;
