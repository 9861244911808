import { useEffect, useState } from "react";
import Stadium from "../asset/bg-image.jpg";
import SignIn from "./Login";


const AuthenticationPage = () => {
  return (
    <div
      className="min-h-screen bg-cover bg-center bg-no-repeat"
      style={{
        backgroundImage: `url(${Stadium})`,
      }}
    >
      <SignIn />
    </div>
  );
};

export default AuthenticationPage;
