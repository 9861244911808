import axios from "axios";
import { Utils } from "../Utils/utils";
import { loginFailure, loginStart, loginSuccess } from "./adminRedux";
import axiosInstance from "../Interceptor/Interceptor";
//import useAxiosInterceptor from "../Interceptor/useAxiosInterceptor";
// axios.defaults.baseURL = "http://localhost:8000/api/v1/";
// axios.defaults.timeout = 10000;

//const ONLINE_URL = "http://localhost:8000/api/v1/";
const ONLINE_URL = 'https://api.betracle.ai/api/v1/';
const AxiosConfig = {
  timeout: 20000
}
//const axiosInstance = useAxiosInterceptor();

export const login = async (dispatch, email, password) => {
  dispatch(loginStart());
  try {
    let data = {
      username: email,
      password: password,
    };

    let url = `${ONLINE_URL}admin/login`;
    const info = await axios.post(url, data);

    if (info.status === 200) {
      Utils.errorStatusCode = 200;
      dispatch(loginSuccess(info.data));
      localStorage.setItem("AuthToken", JSON.stringify(info.data))
    }
    else {
      Utils.errorStatusCode = 0;
      dispatch(loginFailure());
    }
    
   
  } catch (error) {
    Utils.errorStatusCode = error.response.status;
    dispatch(loginFailure());
    console.log(error)
  }
};

export const Register = async (data) => {
  try {
    let url = `${ONLINE_URL}register`;
    const info = await axiosInstance.post(
      url,
      data,
    );
    return info;
  } catch (error) {
    throw error;
  }
};

export const ChangePassword = async (data, userId) => {
  try {
    let url = `${ONLINE_URL}updatePassword/${userId}`;
    const info = await axiosInstance.put(
      url,
      data,
    );
    return info;
  } catch (error) {
    throw error;
  }
};

export const FetchGames =  async () => {
  try {
    let url = `${ONLINE_URL}all-games`;
    const response = await axiosInstance.get(url);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const FetchUsers =  async () => {
  try {
    let url = `${ONLINE_URL}users`;
    const response = await axiosInstance.get(url);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const FetchCarousels =  async () => {
  try {
    let url = ONLINE_URL + "carousels";
    const response = await axiosInstance.get(url);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const NewCarousel =  async (payload) => {
  try {
    let url = ONLINE_URL + "carousel";

    const response = await axiosInstance.post(url, payload);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const UpdateCarousel =  async (carouselId, payload) => {
  try {
    let url = ONLINE_URL + "carousel/update/" + carouselId;
    const response = await axiosInstance.put(url, payload);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const DeleteCarousel =  async (carouselId) => {
  try {
    let url = ONLINE_URL + "carousel/" + carouselId;
    const response = await axiosInstance.delete(url);
    return response.data;
  } catch (error) {
    throw error;
  }
};



export const GetAllMarkets =  async () => {
  try {
    let url = ONLINE_URL + "markets";
    const response = await axiosInstance.get(url);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const UpdateMarket =  async (marketId, payload) => {
  try {
    let url = ONLINE_URL + "market/update/" + marketId;
    const response = await axiosInstance.put(url, payload);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const DeleteMarket =  async (marketId) => {
  try {
    let url = ONLINE_URL + "market/delete/" + marketId;
    const response = await axiosInstance.delete(url);
    return response.data;
  } catch (error) {
    throw error;
  }
};


export const NewMarket =  async (payload) => {
  try {
    let url = ONLINE_URL + "newMarket";
    const response = await axiosInstance.post(url, payload);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const NewAdmin =  async (payload) => {
  try {
    let url = ONLINE_URL + "admin/create";
    const response = await axiosInstance.post(url, payload);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const GetAdmins =  async () => {
  try {
    let url = ONLINE_URL + "admins";
    const response = await axiosInstance.get(url);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const GetRoles =  async () => {
  try {
    let url = ONLINE_URL + "roles";
    const response = await axiosInstance.get(url);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const DeleteAdmin =  async (adminId) => {
  try {
    let url = ONLINE_URL + "admin/delete/" + adminId;
    const response = await axiosInstance.delete(url);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const UpdateAdminPassword =  async (adminId, payload) => {
  try {
    let url = ONLINE_URL + "admin/update-password/" + adminId;
    const response = await axiosInstance.put(url, payload);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const GetTotalPredictions =  async () => {
  try {
    let url = ONLINE_URL + "users/total-prediction";
    const response = await axiosInstance.get(url);
    return response.data;
  } catch (error) {
    throw error;
  }
};

