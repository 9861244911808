import React, { useEffect, useState } from "react";
import DHeader from "../components/Header";
import Export from "../../../asset/export.svg";
import { FetchUsers } from "../../../Redux/apiCalls";
import { formatDateString } from "../../../Utils/utils";
import ReactPaginate from "react-paginate";
import Papa from "papaparse";

function Users() {
  const [UserRecord, setUserRecord] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [usersPerPage] = useState(10);

  const handleSearchChange = (e) => {
    setSearchValue(e.target.value);
  };

  const filteredUsers = UserRecord.filter((user) => {
    const userId = String(user.id || "").toLowerCase();
    const email = (user.email || "").toLowerCase();
    const walletId = (user.wallet_id || "").toLowerCase();

    return (
      userId.includes(searchValue.toLowerCase()) ||
      email.includes(searchValue.toLowerCase()) ||
      walletId.includes(searchValue.toLowerCase())
    );
  });

  const displayedUsers = filteredUsers.slice(
    currentPage * usersPerPage,
    (currentPage + 1) * usersPerPage
  );

  const GetUsers = async () => {
    const _Tmp = await FetchUsers();
    setUserRecord(_Tmp.users);
  };

  useEffect(() => {
    GetUsers();
  }, []);

  const handlePageClick = (data) => {
    setCurrentPage(data.selected);
  };

  const handleExport = () => {
    const csvData = UserRecord.map((user, index) => ({
      index: index + 1,
      userId: user.id,
      email: user.email,
      dateJoined: user.createdAt,
      predictions: user.totalPredictionPoint,
      subscriptions: user.subscriptions,
      walletAddress: user.wallet_id,
    }));
    const csv = Papa.unparse(csvData);
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "users.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div>
      <DHeader />

      <div className="bg-[#151515] p-4 md:p-8 lg:p-16 m-4 md:m-8 lg:m-16 rounded-lg">
        <div className="flex flex-col md:flex-row justify-between items-start md:items-center mb-6">
          <div className="mb-4 md:mb-0">
            <h3 className="text-lg md:text-xl text-[#D9D9D9] mb-2 md:mb-5">
              Total Users
            </h3>
            <p className="text-xl md:text-2xl text-[#D9D9D9] font-extrabold font-[Oxta]">
              {UserRecord?.length}
            </p>
          </div>

          <div className="flex flex-col md:flex-row items-start md:items-center space-y-4 md:space-y-0 md:space-x-8">
            <div
              className="flex items-center space-x-2 cursor-pointer"
              onClick={handleExport}
            >
              <h3 className="text-lg md:text-xl text-[#D9D9D9]">Export</h3>
              <img src={Export} alt="Export" className="w-6 h-6" />
            </div>
            <div className="w-full">
              <input
                type="text"
                className="w-full h-10 md:h-12 px-3 md:px-4 py-2 rounded-lg focus:outline-none text-[#D9D9D9] placeholder-[#D9D9D9]"
                placeholder="Search User Id, Email, or Wallet ID"
                style={{ background: "#545454" }}
                value={searchValue}
                onChange={handleSearchChange}
              />
            </div>
          </div>
        </div>

        <table className="min-w-full border border-collapse border-[#D9D9D9] rounded-xl">
          <thead className="bg-[#434343]">
            <tr>
              <th className="px-4 md:px-6 py-2 md:py-3 text-left text-xs md:text-sm font-medium text-gray-300 uppercase tracking-wider border border-slate-600">
                index
              </th>
              <th className="px-4 md:px-6 py-2 md:py-3 text-left text-xs md:text-sm font-medium text-gray-300 uppercase tracking-wider border border-slate-600">
                user id
              </th>
              <th className="px-4 md:px-6 py-2 md:py-3 text-left text-xs md:text-sm font-medium text-gray-300 uppercase tracking-wider border border-slate-600">
                email
              </th>
              <th className="px-4 md:px-6 py-2 md:py-3 text-left text-xs md:text-sm font-medium text-gray-300 uppercase tracking-wider border border-slate-600">
                date joined
              </th>
              <th className="px-4 md:px-6 py-2 md:py-3 text-left text-xs md:text-sm font-medium text-gray-300 uppercase tracking-wider border border-slate-600">
                no of predictions
              </th>
              <th className="px-4 md:px-6 py-2 md:py-3 text-left text-xs md:text-sm font-medium text-gray-300 uppercase tracking-wider border border-slate-600">
                no of subscriptions
              </th>
              <th className="px-4 md:px-6 py-2 md:py-3 text-left text-xs md:text-sm font-medium text-gray-300 uppercase tracking-wider border border-slate-600">
                wallet address
              </th>
            </tr>
          </thead>
          <tbody className="divide-y text-[#D9D9D9]">
            {displayedUsers.map((user, index) => (
              <tr key={index}>
                <td className="px-4 md:px-6 py-2 md:py-4 border border-slate-600">
                  {index + 1 + currentPage * usersPerPage}
                </td>
                <td className="px-4 md:px-6 py-2 md:py-4 border border-slate-600">
                  {user.id}
                </td>
                <td className="px-4 md:px-6 py-2 md:py-4 border border-slate-600">
                  {user.email}
                </td>
                <td className="px-4 md:px-6 py-2 md:py-4 border border-slate-600">
                  {formatDateString(user.createdAt)}
                </td>
                <td className="px-4 md:px-6 py-2 md:py-4 border border-slate-600">
                  {user.totalPredictionPoint}
                </td>
                <td className="px-4 md:px-6 py-2 md:py-4 border border-slate-600">
                  {user.subscriptions}
                </td>
                <td className="px-4 md:px-6 py-2 md:py-4 border border-slate-600">
                  {user.wallet_id}
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        <div className="mt-4">
          <ReactPaginate
            previousLabel={"Previous"}
            nextLabel={"Next"}
            breakLabel={"..."}
            pageCount={Math.ceil(filteredUsers.length / usersPerPage)}
            marginPagesDisplayed={2}
            pageRangeDisplayed={3}
            onPageChange={handlePageClick}
            containerClassName={"flex justify-center space-x-2"}
            pageClassName={"px-4 py-2 bg-[#434343] text-[#D9D9D9] rounded-lg"}
            previousClassName={
              "px-4 py-2 bg-[#434343] text-[#D9D9D9] rounded-lg"
            }
            nextClassName={"px-4 py-2 bg-[#434343] text-[#D9D9D9] rounded-lg"}
            breakClassName={"px-4 py-2 bg-[#434343] text-[#D9D9D9] rounded-lg"}
            activeClassName={"bg-[#D9D9D9] text-black"}
          />
        </div>
      </div>
    </div>
  );
}

export default Users;
