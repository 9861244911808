import Admins from "./Cards/Admins";
import Slides from "./Cards/Slides";
import TotalPredictions from "./Cards/TotalPredictions";
import TotalSubscription from "./Cards/TotalSubscription";
import TotalUsers from "./Cards/TotalUsers";
import Markets from "./Markets/Markets";
import DHeader from "./components/Header";
import { useSelector } from "react-redux";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { Menu } from "./components/Menu";
import DownloadGames from "./Cards/DownloadGames";

function DashboardIndex() {
  const [showSidebar, setShowSidebar] = useState(false);

  const user = useSelector((state) => state.admin.currentUser);

  const toggleSidebar = () => {
    setShowSidebar(!showSidebar);
  };

  return (
    <div className="bg-black min-h-screen">
      <DHeader toggleSidebar={toggleSidebar}/>

      {showSidebar && (
        <div
          className="absolute w-full h-full bg-[#000000] opacity-75 z-10 md:hidden"
          onClick={toggleSidebar}
        ></div>
      )}

      {showSidebar && (
        <motion.div
          initial={{ x: "100%" }} // Start off-screen for mobile
          animate={{ x: showSidebar ? 0 : "100%" }} // Slide in/out smoothly
          exit={{ x: "100%" }} // Closing animation for mobile
          transition={{ duration: 0.9 }} // Customize animation duration
          style={{
            position: "fixed",
            top: 0,
            right: 0,
            width: "90%", // Adjust sidebar width as needed
            height: "100vh", // Full viewport height for mobile
            zIndex: 20, // Ensure sidebar sits on top of other elements
          }}
        >
          <Menu toggleSidebar={toggleSidebar} />
        </motion.div>
      )}
      <div className="flex flex-wrap justify-center gap-4 mb-5 p-5 md:p-1">
        {/* User Card */}
        <TotalUsers />
        {/* Subscription Card */}
        <TotalSubscription />
        {/* Predictions Card */}
        <TotalPredictions />
        <Markets />
      </div>

      <div className="flex flex-wrap justify-center items-start gap-4 p-5 md:p-1">
        {user.user.roleId === 1 && <Admins />}

        <Slides />
        <DownloadGames />
      </div>

      <div className="flex flex-wrap justify-center gap-4"></div>
    </div>
  );
}

export default DashboardIndex;
