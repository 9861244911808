import { useNavigate } from "react-router-dom";


function Slides() {
    const navigate = useNavigate();
    return (
        <div className="w-full md:w-80 bg-[#1E1E1E] border-radius shadow-md rounded-lg p-4" style={{ boxShadow: '5px 6px 0 0 #000000' }}>
            <h3 className="text-xl text-[#D9D9D9] mb-5">Slides</h3>
            <button onClick={() => navigate('/slides')} className="border-2 border-[#00B2FF] text-[#00B2FF] px-4 py-2 border-radius shadow-md flex items-center justify-center font-extrabold w-[150px]">
                <span className="mr-2  font-[900] text-[1rem]">Update</span>
            </button>

        </div>
    )
}

export default Slides