import { useEffect, useState } from "react";
import AddIcon from "../../../asset/add1.png";
import AddAdminModal from "../../Modals/AddAdminModal";
import React from "react";
import { DeleteAdmin, GetAdmins, UpdateAdminPassword } from "../../../Redux/apiCalls";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";

function Admins() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isResetPinModalOpen, setIsResetPinModalOpen] = useState(false);
  const [admins, setAdmins] = useState([]);
  const [currentAdmin, setCurrentAdmin] = useState({});

  const user = useSelector((state) => state.admin.currentUser);

  const Admins = async () => {
    try {
      const _Tmp = await GetAdmins();
      setAdmins(_Tmp.admins);
    } catch (error) {
      console.log(error);
    }
  };

  const openModal = (data) => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const openResetModal = (data) => {
    setIsResetPinModalOpen(true);
  };

  const closeResetModal = () => {
    setIsResetPinModalOpen(false);
  };

  const openDeleteModal = (data) => {
    if (user.user.roleId !== 1) {
      alert("Access Denied");
    } else {
      setCurrentAdmin(data);
      setIsDeleteModalOpen(true);
    }
  };

  const closeDeleteModal = () => {
    setIsDeleteModalOpen(false);
  };

  useEffect(() => {
    Admins();
  }, []);
  return (
    <div
  className="w-full md:w-[40rem] mb-5 md:mb-0 bg-[#1E1E1E] rounded-lg shadow-md p-4 md:p-6"
  style={{ boxShadow: "5px 6px 0 0 #000000" }}
>
  <ToastContainer autoClose={5000} />
  <div className="flex flex-row md:flex-row justify-between items-start md:items-center mb-5">
    <div className="mb-4 md:mb-0">
      <h3 className="text-xl text-[#D9D9D9] mb-2 md:mb-5">Admin</h3>
      <p className="text-2xl text-[#D9D9D9] font-extrabold font-[Oxta]">
        {admins?.length}
      </p>
    </div>

    <div
      onClick={openModal}
      className="w-8 h-8 md:w-10 md:h-10 text-white cursor-pointer"
    >
      <img src={AddIcon} alt="Icon" className="text-[#00FF87]" />
    </div>
  </div>

  <div>
    {admins?.map((admin, i) => (
      <div key={i} className="flex flex-col md:flex-row justify-between items-start md:items-center mb-4 md:mb-5">
        <h3 className="text-xl text-[#D9D9D9] mb-2 md:mb-0">{admin?.username}</h3>
        
        <div className="flex space-y-3 md:space-y-0 md:space-x-4 flex-col md:flex-row w-full md:w-auto">
          <button
            onClick={() => openResetModal(admin)}
            className="bg-[#FF9800] text-[#000000] px-4 py-2 md:px-6 md:py-4 rounded-lg shadow-md flex items-center justify-center font-extrabold w-full md:w-[200px]"
          >
            <span className="mr-2 font-[900] text-[1rem]">Reset Pin</span>
          </button>

          <button
            onClick={() => openDeleteModal(admin)}
            className="bg-[#F44336] text-[#000000] px-4 py-2 md:px-6 md:py-4 rounded-lg shadow-md flex items-center justify-center font-extrabold w-full md:w-[200px]"
          >
            <span className="mr-2 font-[900] text-[1rem]">Remove</span>
          </button>
        </div>
      </div>
    ))}
  </div>

  <AddAdminModal closeModal={closeModal} isModalOpen={isModalOpen} />
  <DeleteModal
    closeModal={closeDeleteModal}
    isModalOpen={isDeleteModalOpen}
    admin={currentAdmin}
  />
  <ResetPinModal
    closeModal={closeResetModal}
    isModalOpen={isResetPinModalOpen}
    admin={currentAdmin}
  />
</div>

  );
}

export default Admins;

export const DeleteModal = ({ closeModal, isModalOpen, admin }) => {
  const HandleDelete = async () => {
    try {
      const _Tmp = await DeleteAdmin(admin.id);
      if (_Tmp.message === "Successful") {
        closeModal();
        toast.success("Market deleted successfully");
        window.location.reload();
      }
      return;
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div>
      {isModalOpen && (
        <div className="modal fixed inset-0 w-full h-full flex items-center justify-center z-50">
          <div
            onClick={closeModal}
            className="modal-overlay absolute w-full h-full bg-[#000000] opacity-95"
          ></div>
          <ToastContainer autoClose={5000} />
          <div
            className="modal-container bg-[#545454] w-11/12 md:max-w-md mx-auto border-radius shadow-lg z-50 overflow-y-auto"
            style={{ background: "rgb(16 16 16 / 79%)" }}
          >
            <div className="modal-content py-4 text-left px-6">
              <div className="text-xl font-bold mb-4 p-3">
                <div className="flex items-center">
                  <div className=" text-center">
                    <span className="text-2xl font-bold text-[#8D8D8D]">
                      Confirmation
                    </span>
                  </div>
                </div>
              </div>

              <div className="mb-4">
                <p className="text-[#8D8D8D]">
                  Are you sure you want to delete {admin?.username}?
                </p>
              </div>

              <div className="flex justify-between space-x-4 mt-3">
                <button
                  onClick={closeModal}
                  className="bg-[#FF9800] text-[#000000] px-4 py-4 border-radius shadow-md flex items-center justify-center w-[150px]"
                >
                  <span className="mr-2 text-[1rem]">Cancel</span>
                </button>

                <button
                  onClick={HandleDelete}
                  className="bg-[#F44336] text-[#000000] px-4 py-4 border-radius shadow-md flex items-center justify-center font-extrabold w-[150px]"
                >
                  <span className="mr-2 text-[1rem]">Delete</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export const ResetPinModal = ({ closeModal, isModalOpen, admin }) => {
  const [password, setPassword] = useState("");
  const [cPassword, setCPassword] = useState("");

  const HandleResetPin = async () => {
    try {
      if (password === "" || cPassword === "") {
        toast.warn("All fields are required.");
      } else if (password !== cPassword) {
        toast.warn("Passwords do not match");
      }
      else {
        let payload = {
          password: password
        }

        const info = await UpdateAdminPassword(admin.id, payload);
        console.log(info)
      }
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <div>
      {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          <div
            onClick={closeModal}
            className="modal-overlay absolute w-full h-full bg-[#000000] opacity-95"
          ></div>

          <ToastContainer autoClose={5000} />
          <div
            className="modal-container  w-11/12 md:max-w-md mx-auto border-radius shadow-lg overflow-y-auto"
            style={{ zIndex: 9999, background: "rgb(16 16 16 / 79%)" }}
          >
            {/* Add your modal content here */}
            <div className="modal-content py-4 text-left px-6">
              <div className="my-12">
                <input
                  type="password"
                  className="w-full h-12 px-4 py-2 border-radius focus:outline-none text-[#8D8D8D] placeholder-[#545454]"
                  placeholder="Enter Password"
                  style={{
                    background: "rgb(20 20 20 / 79%)",
                    fontSize: "12px",
                  }}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>

              <div className="my-12 mb-24">
                <input
                  type="password"
                  className="w-full h-12 px-4 py-2 border-radius focus:outline-none text-[#8D8D8D] placeholder-[#545454]"
                  placeholder="Verify Password"
                  style={{
                    background: "rgb(20 20 20 / 79%)",
                    fontSize: "12px",
                  }}
                  value={cPassword}
                  onChange={(e) => setCPassword(e.target.value)}
                />
              </div>

              <div className="mb-4 ">
                <button
                  onClick={HandleResetPin}
                  type="submit"
                  className="bg-[#00FF87] flex justify-center items-center text-[#000000] font-[Oxta] font-[300] text-[1rem] py-4 px-4 w-full border-radius"
                >
                  Reset Pin
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
