import DHeader from "../components/Header";
import Edit from "../../../asset/edit.svg";
import Delete from "../../../asset/delete.svg";
import Add from "../../../asset/add.svg";
import Export from "../../../asset/export.svg";
import Papa from "papaparse";

import { useEffect, useRef, useState } from "react";
import {
  DeleteMarket,
  GetAllMarkets,
  NewMarket,
  UpdateMarket,
} from "../../../Redux/apiCalls";
import { ToastContainer, toast } from "react-toastify";
import Loader from "../../../Utils/Loader/Loader";

function MarketCard() {
  const [markets, setMarkets] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isMarketModalOpen, setIsMarketModalOpen] = useState(false);
  const [isUpdateMarketModalOpen, setIsUpdateMarketModalOpen] = useState(false);
  const [currentMarket, setCurrentMarket] = useState({});

  const GetMarkets = async () => {
    const _Tmp = await GetAllMarkets();
    setMarkets(_Tmp.info);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const closeMarketModal = () => {
    setIsMarketModalOpen(false);
  };

  const openModal = (data) => {
    setIsModalOpen(true);
    setCurrentMarket(data);
  };

  const openMarketModal = () => {
    setIsMarketModalOpen(true);
  };

  const openUpdateModal = (data) => {
    setIsUpdateMarketModalOpen(true);
    setCurrentMarket(data);
  };

  const closeUpdateModal = () => {
    setIsUpdateMarketModalOpen(false);
  };

  useEffect(() => {
    GetMarkets();
  }, []);

  const handleExport = () => {
    const csvData = markets.map((market, index) => ({
      index: index + 1,
      marketId: market.id,
      marketName: market.market_name,
      isActive: market.isActive,
    }));
    const csv = Papa.unparse(csvData);
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "markets.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div>
      <DHeader />

      <div className="bg-[#151515] p-16 m-16 rounded-lg">
        <div className="flex justify-between items-center">
          <div>
            <h3 className="text-xl text-[#D9D9D9] mb-5">Markets</h3>
            <p className="text-2xl text-[#D9D9D9] font-extrabold font-[Oxta] mb-5">
              {markets?.length}
            </p>
          </div>

          <div className="flex items-center space-x-8">
            <div
              onClick={openMarketModal}
              className="flex items-center space-x-2 cursor-pointer"
            >
              <h3 className="text-xl text-[#D9D9D9] ">Add Market</h3>
              <img src={Add} alt="Export" className="w-6 h-6" />
            </div>
            <div className="mb-2 md:mb-4 mt-3">
              <div
                className="flex items-center space-x-2 cursor-pointer"
                onClick={handleExport}
              >
                <h3 className="text-lg md:text-xl text-[#D9D9D9]">Export</h3>
                <img src={Export} alt="Export" className="w-6 h-6" />
              </div>
            </div>
          </div>
        </div>

        <table className="min-w-full border border-collapse border-[#D9D9D9] rounded-xl">
          <thead className="bg-[#434343]">
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider border border-slate-600">
                index
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider border border-slate-600">
                market name
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider border border-slate-600">
                isActive
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider border border-slate-600">
                Edit
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider border border-slate-600">
                Delete
              </th>
            </tr>
          </thead>
          <tbody className="divide-y text-[#D9D9D9]">
            {markets?.map((market, index) => (
              <tr key={index}>
                <td className="px-6 py-4 border border-slate-600">
                  {index + 1}
                </td>
                <td className="px-6 py-4 border border-slate-600">
                  {market.market_name}
                </td>
                <td className="px-6 py-4 border border-slate-600">
                  <div className="flex items-center justify-center">
                    <div
                      className={`flex items-center justify-center ${
                        market.isActive ? "bg-green-500" : "bg-red-500"
                      } rounded-full w-[50%]`}
                    >
                      <span className="text-[#D9D9D9] p-2 text-[0.8rem]">
                        {market.isActive ? "Active" : "Not Active"}
                      </span>
                    </div>
                  </div>
                </td>
                <td className="px-6 py-4 border border-slate-600">
                  <div
                    onClick={() => openUpdateModal(market)}
                    className="flex items-center space-x-2 cursor-pointer"
                  >
                    <img src={Edit} alt="Export" className="w-6 h-6" />
                  </div>
                </td>
                <td className="px-6 py-4 border border-slate-600">
                  <div
                    onClick={() => openModal(market)}
                    className="flex items-center space-x-2 cursor-pointer"
                  >
                    <img src={Delete} alt="Export" className="w-6 h-6" />
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <DeleteModal
        closeModal={closeModal}
        isModalOpen={isModalOpen}
        market={currentMarket}
      />
      <AddMarketModal
        closeModal={closeMarketModal}
        isModalOpen={isMarketModalOpen}
      />
      <UpdateMarketModal
        closeModal={closeUpdateModal}
        isModalOpen={isUpdateMarketModalOpen}
        market={currentMarket}
      />
    </div>
  );
}

export default MarketCard;

export const DeleteModal = ({ closeModal, isModalOpen, market }) => {
  const HandleDelete = async () => {
    const _Tmp = await DeleteMarket(market.id);
    if (_Tmp.message === "Successful") {
      closeModal();
      toast.success("Market deleted successfully");
      window.location.reload();
    }
    return;
  };
  return (
    <div>
      {isModalOpen && (
        <div className="modal fixed inset-0 w-full h-full flex items-center justify-center z-50">
          <div
            onClick={closeModal}
            className="modal-overlay absolute w-full h-full bg-[#000000] opacity-95"
          ></div>

          <div
            className="modal-container bg-[#545454] w-11/12 md:max-w-md mx-auto border-radius shadow-lg z-50 overflow-y-auto"
            style={{ background: "rgb(16 16 16 / 79%)" }}
          >
            <div className="modal-content py-4 text-left px-6">
              <div className="text-xl font-bold mb-4 p-3">
                <div className="flex items-center">
                  <div className=" text-center">
                    <span className="text-2xl font-bold text-[#8D8D8D]">
                      Confirmation
                    </span>
                  </div>
                </div>
              </div>

              <div className="mb-4">
                <p className="text-[#8D8D8D]">
                  Are you sure you want to delete {market?.market_name}?
                </p>
              </div>

              <div className="flex justify-between space-x-4 mt-3">
                <button
                  onClick={closeModal}
                  className="bg-[#FF9800] text-[#000000] px-4 py-4 border-radius shadow-md flex items-center justify-center w-[150px]"
                >
                  <span className="mr-2 text-[1rem]">Cancel</span>
                </button>

                <button
                  onClick={HandleDelete}
                  className="bg-[#F44336] text-[#000000] px-4 py-4 border-radius shadow-md flex items-center justify-center font-extrabold w-[150px]"
                >
                  <span className="mr-2 text-[1rem]">Delete</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export const AddMarketModal = ({ closeModal, isModalOpen }) => {
  const [marketName, setMarketName] = useState("");
  const [isActive, setIsActive] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleClick = async () => {
    setIsLoading(true);
    try {
      if (marketName === "") {
        toast.warn("Market name is empty");
      } else {
        let payload = {
          market_name: marketName,
          isActive: isActive,
        };

        const info = await NewMarket(payload);
        console.log(info);
        if (info.message === "Successful") {
          toast.success("Market added successfully");
          setIsLoading(false);
          closeModal();
          window.location.reload();
        } else {
          toast.warn("An error has occurred");
          setIsLoading(false);
        }
      }
    } catch (error) {
      setIsLoading(false);
      toast.warn("An error has occurred");
    }
  };
  return (
    <div>
      {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          <div
            onClick={closeModal}
            className="modal-overlay absolute w-full h-full bg-[#000000] opacity-95"
          ></div>

          <ToastContainer autoClose={5000} />
          <div
            className="modal-container  w-11/12 md:max-w-md mx-auto border-radius shadow-lg overflow-y-auto"
            style={{ zIndex: 9999, background: "rgb(16 16 16 / 79%)" }}
          >
            {/* Add your modal content here */}
            <div className="modal-content py-4 text-left px-6">
              <div className="my-12">
                <input
                  type="text"
                  className="w-full h-12 px-4 py-2 border-radius focus:outline-none text-[#8D8D8D] placeholder-[#545454]"
                  placeholder="Enter market name"
                  style={{
                    background: "rgb(20 20 20 / 79%)",
                    fontSize: "12px",
                  }}
                  value={marketName}
                  onChange={(e) => setMarketName(e.target.value)}
                />
              </div>

              <div className="my-12">
                <label className="flex items-center">
                  <input
                    type="checkbox"
                    className="form-checkbox h-5 w-5 text-green-500"
                    checked={isActive}
                    onChange={() => setIsActive(!isActive)} // Toggle the state on change
                  />
                  <span className="ml-2 text-gray-300">Is Active</span>
                </label>
              </div>

              <div className="mb-4 ">
                <button
                  type="submit"
                  className="bg-[#00FF87] flex justify-center items-center text-[#000000] font-[Oxta] font-[300] text-[1rem] py-4 px-4 w-full border-radius"
                  onClick={handleClick}
                >
                  Add Market
                </button>
              </div>
            </div>
          </div>
          {isLoading && <Loader />}
        </div>
      )}
    </div>
  );
};

export const UpdateMarketModal = ({ closeModal, isModalOpen, market }) => {
  const [marketName, setMarketName] = useState(""); // Set initial value from market prop

  const [isActive, setIsActive] = useState(false); // Set initial value from market prop

  const [isLoading, setIsLoading] = useState(false);
  const [currentId, setCurrentId] = useState(0);

  const inputRef = useRef(null);

  const handleUpdateMarket = async () => {
    setIsLoading(true);
    try {
      if (marketName === "") {
        toast.warn("The market name is empty");
      } else {
        let payload = {
          market_name: marketName,
          isActive: isActive,
        };

        const info = await UpdateMarket(currentId, payload);

        if (info.message === "Successful") {
          toast.success("Market updated successfully");
          setIsLoading(false);
          closeModal();
          window.location.reload();
        } else {
          toast.warn("An error occurred.");
          setIsLoading(false);
        }
      }
    } catch (error) {
      setIsLoading(false);
      toast.warn("An error occurred.");
    }
  };

  useEffect(() => {
    setIsActive(market?.isActive);
    setMarketName(market?.market_name);
    setCurrentId(market?.id);
  }, [market]);

  const CloseModal = async () => {
    closeModal();
    setMarketName("");
  };

  return (
    <div>
      {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          <div
            onClick={CloseModal}
            className="modal-overlay absolute w-full h-full bg-[#000000] opacity-95"
          ></div>
          <ToastContainer autoClose={5000} />
          <div
            className="modal-container  w-11/12 md:max-w-md mx-auto border-radius shadow-lg overflow-y-auto"
            style={{ zIndex: 9999, background: "rgb(16 16 16 / 79%)" }}
          >
            <div className="modal-content py-4 text-left px-6">
              <div className="my-12">
                <input
                  type="text"
                  className="w-full h-12 px-4 py-2 border-radius focus:outline-none text-[#8D8D8D] placeholder-[#545454]"
                  placeholder="Enter market name"
                  style={{
                    background: "rgb(20 20 20 / 79%)",
                    fontSize: "12px",
                  }}
                  // ref={inputRef}
                  value={marketName}
                  onChange={(e) => setMarketName(e.target.value)}
                />
              </div>
              <div className="my-12">
                <label className="flex items-center">
                  <input
                    type="checkbox"
                    className="form-checkbox h-5 w-5 text-green-500"
                    checked={isActive}
                    onChange={() => setIsActive(!isActive)} // Toggle the state on change
                  />
                  <span className="ml-2 text-gray-300">Is Active</span>
                </label>
              </div>
              <div className="mb-4 ">
                <button
                  onClick={handleUpdateMarket}
                  type="submit"
                  className="bg-[#00FF87] flex justify-center items-center text-[#000000] font-[Oxta] font-[300] text-[1rem] py-4 px-4 w-full border-radius"
                >
                  Update Market
                </button>
              </div>
            </div>
          </div>
          {isLoading && <Loader />}
        </div>
      )}
    </div>
  );
};
