import CardIcon from "../../../asset/card-admin.png";

function TotalSubscription() {
    return (
        <div className="w-full md:w-80 bg-[#1E1E1E] border-radius shadow-md rounded-lg p-4">
            <div className="flex justify-between items-center mb-5">
                <h3 className="text-xl text-[#D9D9D9] font-semibold">Total Subscriptions</h3>
                <div className="w-4 h-4 md:w-6 md:h-6 text-white cursor-pointer">
                    <img src={CardIcon} alt="Icon" className="text-[#00FF87]" />
                </div>
            </div>
        
            <p className="text-2xl text-[#02FFF0] font-extrabold font-[Oxta] mb-5">1000</p>
        </div>
    )
}

export default TotalSubscription