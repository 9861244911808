import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import Papa from "papaparse";
import { FetchGames } from "../../../Redux/apiCalls";

function DownloadGames() {
    const [games, setGames] = useState([]);

    useEffect(() => {
        const GetGames = async () => {
            try {
                const _Tmp = await FetchGames();
                setGames(_Tmp.response);
            } catch (error) {
                console.log(error);
            }
        };
        GetGames();
    }, [])
    const handleExport = () => {
        const csvData = games.map((game, index) => ({
          index: index + 1,
          gameId: game.id,
          homeTeam: game.homeName,
          homeLogo: game.homeLogo,
          awayTeam: game.awayName,
          awayLogo: game.awayLogo,
          leagueCountry: game.leagueCountry,
          leagueLogo: game.leagueImage,
          leagueName: game.leagueName,
          leagueSeason: game.leagueSeason,
          date: game.date,

        }));
        const csv = Papa.unparse(csvData);
        const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "games.csv");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      };
    return (
        <div className="w-full md:w-80 bg-[#1E1E1E] border-radius shadow-md rounded-lg p-4" style={{ boxShadow: '5px 6px 0 0 #000000' }}>
            <h3 className="text-xl text-[#D9D9D9] mb-5">Export Games</h3>
            <button onClick={() => handleExport()} className="border-2 border-[#00B2FF] text-[#00B2FF] px-4 py-2 border-radius shadow-md flex items-center justify-center font-extrabold w-[150px]">
                <span className="mr-2  font-[900] text-[1rem]">Export</span>
            </button>

        </div>
    )
}

export default DownloadGames