import React, { useState } from "react";
import CloseIcon from "../../../asset/close.png";
import Exclude from "../../../asset/Logo12.png";
import { logout } from "../../../Redux/adminRedux";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

export const Menu = ({ toggleSidebar }) => {
  const [activeTab, setActiveTab] = useState("Predictions");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const user = useSelector((state) => state.admin.currentUser);

  const Logout = () => {
    dispatch(logout());
    localStorage.clear();
    navigate("/");
  };

  return (
    <div className="bg-black bg-opacity-50 glass-card h-full border-[1px] border-[#2A2A2A]">
      <div className="flex justify-between items-center  p-3 md:hidden">
        <div className="flex items-center justify-start">
          <a href="#" className="flex items-center">
            <img src={Exclude} alt="BLogo" className="w-40 h-16" />
          </a>
        </div>

        <div className="" onClick={toggleSidebar}>
          <img src={CloseIcon} alt="BLogo" className="w-12 h-12" />
        </div>
      </div>

      <div
        id="menu"
        className="flex flex-col items-start space-y-2 mt-4 text-base font-normal pl-4"
      >
        <a href="#" className="text-[#FF9800] text-lg">
          Change Password
        </a>
        <a href="#" className="text-gray-300 text-lg">
          {user.user.roleId === 1
            ? `SuperAdmin ${user.user.username}`
            : `Admin ${user.user.username}`}
        </a>
        <a href="#" className="text-[#FE0606] text-lg" onClick={Logout}>
          Logout
        </a>
      </div>
    </div>
  );
};
