import React from 'react';

const SelectInput = ({ options, value, onChange }) => {
  return (
    <div className="relative inline-block w-full">
      <select
        value={value}
        style={{
            background: "rgb(20 20 20 / 79%)",
            fontSize: "12px",
          }}
        onChange={onChange}
        className="block w-full px-4 py-4 pr-8 leading-tight border-radius focus:outline-none text-[#8D8D8D] placeholder-[#545454] appearance-none"
      >
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      <div className="absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none">
        <svg className="w-4 h-4 text-gray-500" viewBox="0 0 20 20" fill="currentColor">
          <path
            fillRule="evenodd"
            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 011.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
            clipRule="evenodd"
          />
        </svg>
      </div>
    </div>
  );
};

export default SelectInput;
