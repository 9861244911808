import React from 'react';
import { useSelector } from 'react-redux';
import { Outlet, Navigate } from 'react-router-dom'

const PrivateRoutes = () => {
  const user = useSelector((state) => state.admin.currentUser);
    return(
        user.token ? <Outlet/> : <Navigate to="/"/>
    )
}

export default PrivateRoutes