import { useEffect, useState } from "react";
import CardIcon from "../../../asset/card-admin.png";
import { GetTotalPredictions } from "../../../Redux/apiCalls";

function TotalPredictions() {
    const [totalPrediction, setTotalPrediction] = useState(0);

    useEffect(() => {
        fetchTotalPredictions()
    }, []);


    const fetchTotalPredictions = async () => {
        try {
            const response = await GetTotalPredictions()
            setTotalPrediction(response.totalPredictionSum)
        } catch (error) {
            console.error('Error fetching total predictions:', error);
        }
    }

    return (
        <div className="w-full md:w-80 bg-[#1E1E1E] border-radius shadow-md rounded-lg p-4">
            <div className="flex justify-between items-center mb-5">
                <h3 className="text-xl text-[#D9D9D9] font-semibold">Total Predictions</h3>

                <div className="w-4 h-4 md:w-6 md:h-6 text-white cursor-pointer">
                    <img src={CardIcon} alt="Icon" className="text-[#00FF87]" />
                </div>
            </div>

            <p className="text-2xl text-[#FFBD3D] font-extrabold font-[Oxta] mb-5">{totalPrediction}</p>
        </div>
    )
}

export default TotalPredictions