import axios from 'axios';
import { useSelector } from 'react-redux';
const axiosInstance = axios.create({
  timeout: 20000,
});



// Add a request interceptor to include the token in every request
axiosInstance.interceptors.request.use(
  (config) => {
    const AuthToken = JSON.parse(localStorage.getItem('AuthToken'));
    let token = AuthToken.token;
    
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);


// Response interceptor to handle expired token
axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    console.log('Interceptor', error)
    if (error.response.data.error === 'jwt expired') {
      // Assuming you have a logout function to clear the token and handle logout
      logoutUser();
    }
    return Promise.reject(error);
  }
);

const logoutUser = () => {
  // Clear the token from localStorage
  localStorage.removeItem('AuthToken');
  
  // Redirect the user to the login page or handle logout
  window.location.href = '/';  // Adjust the redirect path as necessary
};

export default axiosInstance;


