
import { Navigate, Route, Routes } from "react-router-dom";
import AuthenticationPage from "./Pages/AuthPage";
import PrivateRoutes from "./Utils/privateRoutes";
import MarketCard from "./Pages/Dashboard/Markets/MarketCard";
import DashboardIndex from './Pages/Dashboard/Index';
import SlideUpdate from "./Pages/Dashboard/Screens/SlideUpdate";
import Users from "./Pages/Dashboard/Screens/Users";

function App() {
  return (
    <div>
      <Routes>
        <Route path="/" element={ <AuthenticationPage />} />
        <Route element={<PrivateRoutes />}>
        <Route path="/markets" element={<MarketCard />} />
          <Route path="/dashboard" element={<DashboardIndex />} />
          <Route path="/slides" element={<SlideUpdate />} />
          <Route path="/users" element={<Users />} />
        </Route>
        <Route path='*' element={<Navigate to='/' />} />
      </Routes>
    </div>
  );
}

export default App;
