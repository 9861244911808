import React, { useState } from "react";
import AccountAdd from "../asset/account-add.png";
import PasswordFilled from "../asset/password-filled.png";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Navigate } from "react-router-dom";
import Loader from "../Utils/Loader/Loader";
import EyeOpen from "../asset/eye.svg";
import EyeSlash from "../asset/eye-off.svg";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { Utils } from "../Utils/utils";
import { login } from "../Redux/apiCalls";

const MySwal = withReactContent(Swal);

const SignIn = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);

  const user = useSelector((state) => state.admin.currentUser);

  const togglePasswordVisibility = (e) => {
    e.preventDefault();
    setShowPassword(!showPassword);
  };

  const HandleLogin = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
        if (email === "" || password === "") {
            toast.warn("All fields are required.");
            setIsLoading(false);
          } else {
            const _IAuth = await login(dispatch, email, password);
      
            if (user.message === "Successful") {
              setIsLoggedIn(true);
              toast.success("Authentication and verification successful");
              setIsLoading(false);
            } else {
              if (Utils.errorStatusCode === 400) {
                toast.warn("Empty field is not allowed");
                setIsLoading(false);
              } else if (Utils.errorStatusCode === 403) {
                toast.warn(
                  "Your account is yet to be verified. Please check your email for verification code."
                );
              } else if (Utils.errorStatusCode === 404) {
                toast.warn("Email/Password is incorrect");
                //setPassword('');
                setIsLoading(false);
              } else {
                toast.warn("Try again");
                //setPassword('');
                setIsLoading(false);
              }
            }
          }
    } catch (error) {
        console.log(error);
        setIsLoading(false);
    }
  };
  if (isLoggedIn === false) {
    return (
        <div className="flex justify-center items-center min-h-screen p-2 md:p-0">
          <ToastContainer autoClose={5000} />
    
          {isLoading && <Loader />}
    
          <div className=" p-8 border-radius shadow-lg w-full md:w-96 glass-card">
            <h1 className="text-[1.2rem] md:text-2xl font-semibold mb-2 md:mb-4 text-[#8D8D8D] py-4 md:py-8 font-medium">
              Welcome back
            </h1>
            <form>
              <div className="mb-2 md:mb-4">
                <input
                  type="text"
                  className="w-full h-12 px-4 py-2 border-radius text-[0.7rem] md:text-[1rem] focus:outline-none text-[#8D8D8D] placeholder-[#545454] border"
                  placeholder="Email"
                  style={{
                    background: "rgb(51 51 51 / 79%)",
                    borderColor: "rgb(255 255 255 / 0.1)",
                  }}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="mb-4 relative">
                <input
                  type={showPassword ? "text" : "password"}
                  className="w-full h-12 px-4 py-2 focus:outline-none text-[0.7rem] md:text-[1rem] text-[#8D8D8D] border-radius placeholder-[#545454] border"
                  placeholder="Password"
                  style={{
                    background: "rgb(51 51 51 / 79%)",
                    borderColor: "rgb(255 255 255 / 0.1)",
                  }}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <button
                  className="absolute inset-y-0 right-0 px-3 py-2"
                  onClick={togglePasswordVisibility}
                >
                  {showPassword ? (
                    <img src={EyeSlash} alt="eye slash" className="w-6 h-6" />
                  ) : (
                    <img src={EyeOpen} alt="eye open" className="w-6 h-6" />
                  )}
                </button>
              </div>
              <div className="my-8 md:my-16">
                <button
                  onClick={HandleLogin}
                  type="submit"
                  className="bg-[#00FF87] flex justify-center items-center text-[#000000] font-[Oxta] font-[300] text-[0.8rem] md:text-[1rem] py-4 px-4 w-full rounded"
                  disabled={isLoading}
                >
                  {isLoading ? "Please wait..." : "Log In"}
                </button>
              </div>
            </form>
          </div>
        </div>
      );
  }
  else {
    return <Navigate to='/dashboard' />
  }
  
};

export default SignIn;
