import Logo from "../../../asset/logo.png";
import Exclude from "../../../asset/Exclude.svg";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../../Redux/adminRedux";
import { useNavigate } from "react-router-dom";

function DHeader({ toggleSidebar }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.admin.currentUser);

  const Logout = () => {
    dispatch(logout());
    localStorage.clear();
    navigate("/");
  };
  return (
    <header className="bg-transparent py-4 mb-20 flex items-center justify-between px-4 md:px-8">
      <div className="flex items-center space-x-4">
        <img src={Exclude} alt="BLogo" className="w-16 h-12 md:w-24 md:h-16" />
        <h1 className="text-gray-300 font-[Oxta] text-lg md:text-2xl">
          Betracle
        </h1>
      </div>
      <nav className="hidden md:flex">
        <ul className="flex space-x-4 md:space-x-6 text-sm md:text-base font-light">
          <li className="text-[#FF9800]">
            <a href="#" className="hover:text-[#FF9800]">
              Change Password
            </a>
          </li>
          <li className="text-gray-300">
            <a href="#" className="hover:text-gray-400">
              {user.user.roleId === 1
                ? `SuperAdmin ${user.user.username}`
                : `Admin ${user.user.username}`}
            </a>
          </li>
          <li className="text-[#FE0606]" onClick={Logout}>
            <a href="#" className="hover:text-[#FE0606]">
              Logout
            </a>
          </li>
        </ul>
      </nav>
      <div className="md:hidden">
        <button
        onClick={toggleSidebar}
          id="menu-button"
          aria-label="Toggle menu"
          className="text-gray-300 focus:outline-none"
        >
          {/* Menu icon (use any menu icon here) */}
          <svg
            className="w-6 h-6"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            viewBox="0 0 24 24"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M4 6h16M4 12h16m-7 6h7"
            />
          </svg>
        </button>
      </div>
      
    </header>
  );
}

export default DHeader;
