import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FetchUsers } from "../../../Redux/apiCalls";

function TotalUsers() {
  const [users, setUsers] = useState([]);
  const navigate = useNavigate();

  const Users = async () => {
    try {
      const _Tmp = await FetchUsers();
      setUsers(_Tmp.users);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    Users();
  }, []);
  
  return (
    <div
      className="w-full md:w-80 bg-[#1E1E1E] border-radius shadow-md rounded-lg p-4 "
      style={{ boxShadow: "5px 6px 0 0 #000000" }}
    >
      <h3 className="text-xl text-[#D9D9D9] font-semibold mb-5">Total Users</h3>
      <p className="text-2xl text-[#D9D9D9] font-extrabold font-[Oxta] mb-5">
        {users?.length}
      </p>
      <div className="text-slate-50 flex justify-end">
        <button
          onClick={() => navigate("/users")}
          className="bg-[#00FF87] text-[#000000] px-4 py-2 border-radius shadow-md flex items-center justify-center font-extrabold w-[150px]"
        >
          <span className="mr-2 font-[900] text-[1rem]">View</span>
        </button>
      </div>
    </div>
  );
}

export default TotalUsers;
