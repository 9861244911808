import React, { useState } from 'react';
import { DeleteCarousel } from '../../Redux/apiCalls';

const SlideDeleteModal = ({ closeModal, isModalOpen, carousel }) => {

    const HandleDelete = async () => {
        const _Tmp = await DeleteCarousel(carousel.id);
        if (_Tmp.message === "Successful") {
            closeModal();
        }

    }
    return (
        <div>

            {isModalOpen && (
                <div className="modal fixed inset-0 w-full h-full flex items-center justify-center z-50">
                    <div onClick={closeModal} className="modal-overlay absolute w-full h-full bg-[#000000] opacity-95"></div>

                    <div className="modal-container bg-[#545454] w-11/12 md:max-w-md mx-auto border-radius shadow-lg z-50 overflow-y-auto" style={{ background: 'rgb(16 16 16 / 79%)' }}>

                        <div className="modal-content py-4 text-left px-6">
                            <div className="text-xl font-bold mb-4 p-3">
                                <div className="flex items-center">

                                    <div className=" text-center">
                                        <span className="text-2xl font-bold text-[#8D8D8D]">Confirmation</span>
                                    </div>
                                </div>

                            </div>


                            <div className="mb-4">
                                <p className='text-[#8D8D8D]'>Are you sure you want to delete {carousel.filename}?</p>
                            </div>

                            <div className="flex justify-between space-x-4 mt-3">
                                <button onClick={closeModal} className="bg-[#FF9800] text-[#000000] px-4 py-4 border-radius shadow-md flex items-center justify-center w-[150px]">
                                    <span className="mr-2 text-[1rem]">Cancel</span>
                                </button>

                                <button onClick={HandleDelete} className="bg-[#F44336] text-[#000000] px-4 py-4 border-radius shadow-md flex items-center justify-center font-extrabold w-[150px]">
                                    <span className="mr-2 text-[1rem]">Delete</span>
                                </button>
                            </div>


                        </div>
                    </div>
                </div>
            )}

        </div>
    );
}

export default SlideDeleteModal;
